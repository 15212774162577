import './App.css';
import Terrain from './background/background';

function App() {
  return (
    <div className="App">
      <Terrain/>
    </div>
  );
}

export default App;
